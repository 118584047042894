import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { MenuItem } from './shared/ui/components/menu-item/menu-item.component';
import { logout } from './core/auth/data/+state/auth.actions';
import { selectIsAuthenticated } from './core/auth/data/+state/auth.selectors';
import { AbilityService } from './core/auth/data/services/ability.service';
import { Observable, filter, map } from 'rxjs';
import { arrayBuilder } from './shared/util/array-builder';

export interface MenuGroup {
  group: string;
  items: MenuItem[];
}

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  public menuItems$: Observable<MenuItem[]> = this.abilityService.ability$.pipe(
    map((ability) =>
      arrayBuilder<MenuItem>()
        .add({
          title: 'Akcie',
          icon: 'flash',
          url: '/tabs/actions',
          children: [
            { title: 'Naloženie auta', url: '/tabs/actions/customer-load' },
            {
              title: 'Vyloženie auta na sklade',
              url: '/tabs/actions/warehouse-unload',
            },
            {
              title: 'Vyloženie auta u zákazníka',
              url: '/tabs/actions/customer-unload',
            },
            { title: 'Vykládka na plniarni', url: '/tabs/actions/filling-unload' },
            { title: 'Predaj na predajni', url: '/tabs/actions/store-sale' },
          ],
        })
        .when(ability.can('view users'), {
          title: 'Evidencie',
          icon: 'list',
          url: '/tabs/management',
          children: arrayBuilder()
            .when(ability.can('view users'), { title: 'Používatelia', url: '/tabs/management/users' })
            .when(true, { title: 'Inventúry', url: '/tabs/management/inventory-checks' })
            .build(),
        })
        .add({
          title: 'Profil',
          icon: 'person',
          url: '/tabs/profile',
        })
        .build()
    )
  );

  isAuthenticated$ = this.store.pipe(selectIsAuthenticated);

  constructor(private route: ActivatedRoute, private store: Store, private abilityService: AbilityService) {}

  ngOnInit() {
    //
  }

  handleLogout() {
    this.store.dispatch(logout());
  }
}
