<ion-app>
  <!-- <ion-router-outlet></ion-router-outlet> -->
  <ion-split-pane contentId="main-content" class="app-split-pane" style="--side-max-width: 300px; --side-min-width: 200px">
    <ion-menu contentId="main-content" type="overlay" [disabled]="(isAuthenticated$ | async) === false">
      <ion-header [translucent]="true">
        <ion-toolbar>
          <ion-title> PBgas </ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-content>
        <ion-list id="menu-list" *ngIf="menuItems$ | async as menuItems">
          <ion-menu-toggle [autoHide]="false">
            <ng-container *ngFor="let item of menuItems; let i = index">
              <app-menu-item *ngIf="item" [item]="item"></app-menu-item>
            </ng-container>
          </ion-menu-toggle>
        </ion-list>
      </ion-content>
    </ion-menu>

    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
