import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, map, skipWhile, switchMap, tap } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { Store } from '@ngrx/store';
import { selectAuthLoaded, selectIsAuthenticated } from '../+state/auth.selectors';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router, private store: Store) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.store.pipe(selectIsAuthenticated).pipe(
      map((isAuthenticated) => {
        return (
          isAuthenticated ||
          this.router.createUrlTree(['/login'], {
            queryParams: { returnUrl: state.url },
          })
        );
      })
    );
  }
}
