import { Component, Input, OnInit } from '@angular/core';

export interface MenuItem {
  title: string;
  icon?: string;
  url?: string;
  opened?: boolean;
  children?: (
    | {
        title: string;
        url: string;
      }
    | undefined
  )[];
}

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
})
export class MenuItemComponent implements OnInit {
  @Input() item!: MenuItem;

  constructor() {}

  ngOnInit() {}

  hasChildren(item: MenuItem) {
    return item?.children?.length ?? 0 > 0;
  }

  onClick(e: MouseEvent, item: MenuItem) {
    if (this.hasChildren(item)) {
      e.preventDefault();
      e.stopImmediatePropagation();
    }

    item.opened = !item.opened;
  }

  itemDetailIcon(item: MenuItem) {
    return item.opened ? 'chevron-up-outline' : 'chevron-down-outline';
  }
}
