import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConnectionIndicatorComponent } from './ui/components/connection-indicator/connection-indicator.component';
import { MenuItemComponent } from './ui/components/menu-item/menu-item.component';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { RoleSelectComponent } from './ui/components/role-select/role-select.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BottlePickerComponent } from './ui/components/bottle-picker/bottle-picker.component';
import { NumberInputComponent, NumberInputModalComponent } from './ui/components/number-input/number-input.component';
import { SelectionSummaryComponent } from './ui/components/selection-summary/selection-summary.component';
import { CustomBottleModalComponent } from './ui/components/bottle-picker/custom-bottle-modal.component';
import { ImageUploadComponent } from './ui/components/image-upload/image-upload.component';
import { CarSelectComponent } from './ui/components/car-select/car-select.component';
import { FormErrorsComponent } from './ui/components/form-errors/form-errors.component';
import { BreadcrumbsComponent } from './ui/components/breadcrumbs/breadcrumbs.component';
import { FormulaInputComponent } from './ui/components/formula-input/formula-input.component';
import { DataGridComponent } from './ui/components/data-grid/data-grid.component';
import { DataGridCellDirective } from './ui/components/data-grid/data-grid-cell.directive';
import { PaginationComponent } from './ui/components/pagination/pagination.component';
import { StockSelectComponent } from './ui/components/stock-select/stock-select.component';
import { RfidScannerInputComponent } from './ui/components/rfid-scanner-input/rfid-scanner-input.component';

@NgModule({
  declarations: [
    ConnectionIndicatorComponent,
    MenuItemComponent,
    RoleSelectComponent,
    BottlePickerComponent,
    NumberInputComponent,
    NumberInputModalComponent,
    SelectionSummaryComponent,
    CustomBottleModalComponent,
    ImageUploadComponent,
    CarSelectComponent,
    FormErrorsComponent,
    BreadcrumbsComponent,
    FormulaInputComponent,
    DataGridComponent,
    DataGridCellDirective,
    PaginationComponent,
    StockSelectComponent,
    RfidScannerInputComponent,
  ],
  imports: [CommonModule, IonicModule, ReactiveFormsModule, FormsModule, RouterModule],
  exports: [
    ConnectionIndicatorComponent,
    MenuItemComponent,
    RoleSelectComponent,
    BottlePickerComponent,
    NumberInputComponent,
    SelectionSummaryComponent,
    ImageUploadComponent,
    CarSelectComponent,
    FormErrorsComponent,
    BreadcrumbsComponent,
    FormulaInputComponent,
    DataGridComponent,
    DataGridCellDirective,
    PaginationComponent,
    StockSelectComponent,
    RfidScannerInputComponent,
  ],
})
export class SharedModule {}
