import { createAction, props } from '@ngrx/store';
import {
  ChangePasswordPayload,
  LoginPayload,
  QrLoginPayload,
  ResetPasswordPayload,
  SendResetLinkPayload,
  UpdateMePayload,
} from 'src/app/shared/model/api.model';
import { AuthenticatedUser } from 'src/app/core/auth/model/authenticated-user.model';

export const login = createAction('[Auth] Login', props<{ data: Omit<LoginPayload, 'device_name'> }>());
export const loginSuccess = createAction('[Auth] Login success', props<{ user: AuthenticatedUser }>());
export const loginFailure = createAction('[Auth] Login failure', props<{ error: any }>());

export const qrLogin = createAction('[Auth] QR Login', props<{ data: Omit<QrLoginPayload, 'device_name'> }>());
export const qrLoginSuccess = createAction('[Auth] QR Login success', props<{ user: AuthenticatedUser }>());
export const qrLoginFailure = createAction('[Auth] QR Login failure', props<{ error: any }>());

export const sendPasswordResetLink = createAction('[Auth] Send password reset link', props<{ data: SendResetLinkPayload }>());
export const sendPasswordResetLinkSuccess = createAction('[Auth] Send password reset link success', props<{ email: string }>());
export const sendPasswordResetLinkFailure = createAction('[Auth] Send password reset link failure', props<{ error: any }>());

export const resetPassword = createAction('[Auth] Reset password', props<{ data: ResetPasswordPayload }>());
export const resetPasswordSuccess = createAction('[Auth] Reset password success');
export const resetPasswordFailure = createAction('[Auth] Reset password failure', props<{ error: any }>());

export const changePassword = createAction('[Auth] Change password', props<{ data: ChangePasswordPayload }>());
export const changePasswordSuccess = createAction('[Auth] Change password success');
export const changePasswordFailure = createAction('[Auth] Change password failure', props<{ error: any }>());

export const logout = createAction('[Auth] Logout');
export const logoutSuccess = createAction('[Auth] Logout success');
export const logoutFailure = createAction('[Auth] Logout failure');

export const getMe = createAction('[Auth] Get me');
export const getMeSuccess = createAction('[Auth] Get me success', props<{ user: AuthenticatedUser }>());
export const getMeFailure = createAction('[Auth] Get me failure', props<{ error: any }>());
export const getMeNoop = createAction('[Auth] Get me no-op action');

export const updateMe = createAction('[Auth] Update me', props<{ data: UpdateMePayload }>());
export const updateMeSuccess = createAction('[Auth] Update me success', props<{ user: AuthenticatedUser }>());
export const updateMeFailure = createAction('[Auth] Update me failure', props<{ error: any }>());
