import { createReducer, on } from '@ngrx/store';
import * as authActions from './auth.actions';
import { AuthenticatedUser } from '../../model/authenticated-user.model';

export const AUTH_FEATURE_KEY = 'auth';

export interface AuthState {
  user?: AuthenticatedUser;
  token?: string;
  loaded: boolean;
  loading: boolean;
}

export const initialState: AuthState = {
  loaded: false,
  loading: false,
};

export const authReducer = createReducer(
  initialState,
  on(authActions.login, authActions.qrLogin, (state) => ({ ...state, loading: true })),
  on(authActions.loginSuccess, authActions.qrLoginSuccess, authActions.getMeSuccess, authActions.updateMeSuccess, (state, { user }) => ({
    ...state,
    user,
    loaded: true,
  })),
  on(authActions.getMeFailure, (state) => ({ ...state, loaded: true })),
  on(authActions.logoutSuccess, (state) => ({
    ...state,
    user: undefined,
  })),
  on(authActions.loginSuccess, authActions.loginFailure, authActions.qrLoginSuccess, authActions.qrLoginFailure, (state) => ({
    ...state,
    loading: false,
  }))
);
