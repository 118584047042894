import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { getMe } from './data/+state/auth.actions';

@NgModule({
  declarations: [],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, IonicModule],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (store: Store) => () => store.dispatch(getMe()),
      multi: true,
      deps: [Store],
    },
  ],
})
export class AuthModule {}
