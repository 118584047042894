import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  RouterStateSnapshot,
  TitleStrategy as DefaultTitleStrategy,
} from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable()
export class TitleStrategyService extends DefaultTitleStrategy {
  constructor(private readonly title: Title) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    const title = this.buildTitle(routerState);

    if (title) {
      this.title.setTitle(environment.title + ' - ' + title);
    } else {
      this.title.setTitle(environment.title);
    }
  }
}
