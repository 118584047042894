import { Injectable, Injector, ErrorHandler } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ToastController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { formErrorsActions } from './+state/form-errors.actions';

/** Passes HttpErrorResponse to application-wide error handler */
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private injector: Injector, private toastCtrl: ToastController, private store: Store) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.clearValidationErrors();

    return next.handle(request).pipe(
      tap({
        error: (err: any) => {
          if (err instanceof HttpErrorResponse) {
            // set validation errors
            this.setValidationErrors(err);
            // show toast
            this.showToast(err);
            // pass error to app error handler
            this.injector.get(ErrorHandler)?.handleError(err);
          }
        },
      })
    );
  }

  private setValidationErrors(err: HttpErrorResponse) {
    if (err.status == 422) {
      const errors = Object.fromEntries(
        Object.entries(err.error?.errors || {}).map(([field, field_errors]) => [field, (field_errors as string[])[0]])
      );

      this.store.dispatch(formErrorsActions.setErrors({ errors }));
    }
  }

  private clearValidationErrors() {
    this.store.dispatch(formErrorsActions.clearErrors());
  }

  private showToast(err: HttpErrorResponse) {
    let error = 'Niečo sa pokazilo, skúste to znova';

    switch (err.status) {
      case 422:
        error = 'Chyba validácie';
        break;
      case 403:
        error = 'Na túto akciu nemáte dostatočné oprávnenia';
        break;
      default:
        break;
    }

    this.toastCtrl
      .create({
        message: error,
        duration: 3500,
      })
      .then((toast) => toast.present());
  }
}
