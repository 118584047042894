import { Role } from '../../../shared/model/role.model';
import { Config } from '../../config/model/config.model';

export interface IAuthenticatedUser {
  id: number;
  name: string;
  email: string;
  roles: Role[];
  permissions: string[];
  config: Config;
  qr_login_token: string;
}

export class AuthenticatedUser implements IAuthenticatedUser {
  constructor(
    public id: number,
    public name: string,
    public email: string,
    public roles: Role[],
    public permissions: string[],
    public config: Config,
    public qr_login_token: string
  ) {}

  hasPermission(permission: string) {
    return this.permissions.includes(permission);
  }

  hasRole(role: string) {
    return this.roles.map((r) => r.name).includes(role);
  }
}
