import { createFeatureSelector, createSelector, select } from '@ngrx/store';
import { filter, map, pipe } from 'rxjs';
import { AUTH_FEATURE_KEY, AuthState } from './auth.reducer';

const selectAuthFeature = createFeatureSelector<AuthState>(AUTH_FEATURE_KEY);

export const selectAuth = createSelector(selectAuthFeature, (state) => state);
export const selectAuthenticatedUser = createSelector(selectAuth, (state) => state?.user);
export const selectAuthLoaded = createSelector(selectAuth, (state) => state?.loaded);
export const selectAuthLoading = createSelector(selectAuth, (state) => state?.loading);
export const selectIsAuthenticated = pipe(
  select(selectAuth),
  filter(({ loaded }) => loaded),
  map(({ user }) => !!user)
);
