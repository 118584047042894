import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  ChangePasswordPayload,
  ChangePasswordResponse,
  SendResetLinkPayload,
  SendResetLinkResponse,
  LoginPayload,
  LoginResponse,
  LogoutResponse,
  MeResponse,
  ResetPasswordPayload,
  ResetPasswordResponse,
  QrLoginPayload,
} from 'src/app/shared/model/api.model';
import { AuthenticatedUser, IAuthenticatedUser } from 'src/app/core/auth/model/authenticated-user.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient) {}

  login(data: Omit<LoginPayload, 'device_name'>) {
    return this.http.post<LoginResponse>('/tokens', <LoginPayload>{
      ...data,
      device_name: this.getDeviceName(),
    });
  }

  qrLogin(data: Omit<QrLoginPayload, 'device_name'>) {
    return this.http.post<LoginResponse>('/tokens/qr', <QrLoginPayload>{
      ...data,
      device_name: this.getDeviceName(),
    });
  }

  me() {
    return this.http.get<MeResponse>('/me');
  }

  updateMe(data: any) {
    return this.http.put<MeResponse>('/me', data);
  }

  logout() {
    return this.http.delete<LogoutResponse>('/tokens');
  }

  sendResetLink(data: SendResetLinkPayload) {
    return this.http.post<SendResetLinkResponse>('/password-reset-link', data);
  }

  resetPassword(data: ResetPasswordPayload) {
    return this.http.post<ResetPasswordResponse>('/password-reset', data);
  }

  changePassword(data: ChangePasswordPayload) {
    return this.http.post<ChangePasswordResponse>('/password-change', data);
  }

  createAuthenticatedUser(user: IAuthenticatedUser): AuthenticatedUser {
    return new AuthenticatedUser(user.id, user.name, user.email, user.roles, user.permissions, user.config, user.qr_login_token);
  }

  protected getDeviceName() {
    return navigator.userAgent;
  }
}
