export const dataGet = <T = any>(obj: any, path: string, defaultValue?: any): T => {
  const keys = path.split('.');

  let result = { ...obj };

  for (const key of keys) {
    if (result?.[key] === undefined) {
      return defaultValue;
    }

    result = result[key];
  }

  return result;
};
