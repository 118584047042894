import { createReducer, on } from '@ngrx/store';
import { formErrorsActions } from './form-errors.actions';

export const FORM_ERRORS_FEATURE_KEY = 'formErrors';

export interface FormErrorsState {
  errors: Record<string, string> | null;
}

export const formErrorsInitialState: FormErrorsState = {
  errors: null,
};

export const formErrorsReducer = createReducer(
  formErrorsInitialState,
  on(formErrorsActions.setErrors, (state, { errors }) => ({ errors })),
  on(formErrorsActions.clearErrors, () => ({ errors: null }))
);
