import { Injectable, inject } from '@angular/core';
import { StorageKey, StorageService } from 'src/app/shared/services/storage.service';

const TOKEN_KEY = new StorageKey('auth_token');

@Injectable({
  providedIn: 'root',
})
export class TokenStorageService {
  private storage = inject(StorageService);

  public async getToken(): Promise<string | null> {
    return this.storage.get<string>(TOKEN_KEY);
  }

  public async saveToken(token: string): Promise<void> {
    return this.storage.set(TOKEN_KEY, token);
  }

  public async removeToken(): Promise<void> {
    return this.storage.remove(TOKEN_KEY);
  }
}
