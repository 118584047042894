import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Config } from '../model/config.model';
import { dataGet } from 'src/app/shared/util/data-get';
import { dataSet } from 'src/app/shared/util/data-set';

type Join<K, P> = K extends string | number ? (P extends string | number ? `${K}.${P}` : never) : never;

type Prev = [never, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, ...0[]];

type Paths<T, D extends number = 10> = [D] extends [never]
  ? never
  : T extends object
  ? {
      [K in keyof T]: K extends string | number ? (T[K] extends object ? `${K}` | Join<K, Paths<T[K], Prev[D]>> : `${K}`) : never;
    }[keyof T]
  : '';

type ConfigPaths = Paths<Config>;

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private readonly _config$ = new BehaviorSubject<Config | null>(null);

  private get value() {
    return this._config$.value;
  }

  public readonly config$ = this._config$.asObservable();

  set(config: Config | null): void;
  set(key: keyof Config, value: any): void;
  set(configOrKey: Config | null | keyof Config, value?: any) {
    if (typeof configOrKey === 'object') {
      this._config$.next(configOrKey);
    } else {
      dataSet(this.value, configOrKey, value);
    }
  }

  get(): Config;
  get(path: ConfigPaths, _default?: any): any;
  get(path?: ConfigPaths, _default?: any) {
    if (path === undefined) {
      return this.value;
    }

    return dataGet(this.value, path, _default);
  }
}
