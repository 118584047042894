import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthenticatedUser } from 'src/app/core/auth/model/authenticated-user.model';

const permissions = ['view users', 'create users', 'edit users', 'delete users'] as const;

export type Permission = (typeof permissions)[number];
export type Permissions = Record<Permission, boolean>;

function createPermissionsFor(user: AuthenticatedUser | null): Permissions {
  return Object.fromEntries(permissions.map((permission) => [permission, !!user?.permissions.includes(permission)])) as Permissions;
}

class Ability {
  private _permissions: Permissions;
  private _user: AuthenticatedUser | null = null;

  constructor(user: AuthenticatedUser | null, permissions: Permissions) {
    this._user = user;
    this._permissions = permissions;
  }

  can(permission: Permission): boolean {
    const can = this._permissions[permission];

    if (can === undefined) {
      console.warn(`Permission ${permission} not found`);
    }

    if (this._user?.hasRole('root')) {
      return true;
    }

    return can;
  }
}

@Injectable({
  providedIn: 'root',
})
export class AbilityService {
  private _ability$: BehaviorSubject<Ability> = new BehaviorSubject(new Ability(null, createPermissionsFor(null)));

  get ability() {
    return this._ability$.value;
  }

  ability$ = this._ability$.asObservable();

  updateAbilitiesFor(user: AuthenticatedUser | null) {
    this._ability$.next(new Ability(user, createPermissionsFor(user)));
  }
}
