import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, exhaustMap, catchError, tap, filter, skipWhile, switchMap, finalize } from 'rxjs/operators';
import * as actions from './auth.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { from, of } from 'rxjs';
import { TokenStorageService } from '../services/token-storage.service';
import { Store } from '@ngrx/store';
import { ToastController } from '@ionic/angular';
import { AbilityService } from '../services/ability.service';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { selectAuthenticatedUser } from './auth.selectors';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private tokenStorageService: TokenStorageService,
    private abilityService: AbilityService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private toastCtrl: ToastController
  ) {}

  authResult$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.login),
      exhaustMap(({ data }) =>
        this.authService.login(data).pipe(
          tap(({ token }) => this.tokenStorageService.saveToken(token)),
          map(({ user }) => actions.loginSuccess({ user: this.authService.createAuthenticatedUser(user) })),
          catchError((error: any) => of(actions.loginFailure({ error })))
        )
      )
    )
  );

  qrAuthResult$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.qrLogin),
      exhaustMap(({ data }) =>
        this.authService.qrLogin(data).pipe(
          tap(({ token }) => this.tokenStorageService.saveToken(token)),
          map(({ user }) => actions.qrLoginSuccess({ user: this.authService.createAuthenticatedUser(user) })),
          catchError((error: any) => of(actions.qrLoginFailure({ error })))
        )
      )
    )
  );

  authSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.loginSuccess, actions.qrLoginSuccess),
        tap(() => {
          this.showToast('Prihlásenie prebehlo úspešne');
          this.router.navigate([this.route.snapshot.queryParams?.['returnUrl'] || '/']);
        })
      ),
    { dispatch: false }
  );

  sendResetLinkResult$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.sendPasswordResetLink),
      exhaustMap(({ data }) =>
        this.authService.sendResetLink(data).pipe(
          map(() => actions.sendPasswordResetLinkSuccess({ email: data.email })),
          catchError((error: any) => of(actions.sendPasswordResetLinkFailure({ error })))
        )
      )
    )
  );

  sendResetLinkSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.sendPasswordResetLinkSuccess),
        tap(({ email }) => this.showToast('Na email ' + email + ' bol zaslaný link na obnovenie hesla'))
      ),
    { dispatch: false }
  );

  resetPasswordResult$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.resetPassword),
      exhaustMap(({ data }) =>
        this.authService.resetPassword(data).pipe(
          map(() => actions.resetPasswordSuccess()),
          catchError((error: any) => of(actions.resetPasswordFailure({ error })))
        )
      )
    )
  );

  resetPasswordSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.resetPasswordSuccess),
        tap(() => {
          this.showToast('Heslo bolo úspešne zmenené. Teraz sa môžete prihlásiť.');
          this.router.navigate(['/login']);
        })
      ),
    { dispatch: false }
  );

  changePasswordResult$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.changePassword),
      exhaustMap(({ data }) =>
        this.authService.changePassword(data).pipe(
          map(() => actions.changePasswordSuccess()),
          catchError((error: any) => of(actions.changePasswordFailure({ error })))
        )
      )
    )
  );

  changePasswordSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.changePasswordSuccess),
        tap(() => {
          this.showToast('Heslo bolo úspešne zmenené');
          this.router.navigate(['/tabs/profile']);
        })
      ),
    { dispatch: false }
  );

  updateMeResult$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateMe),
      exhaustMap(({ data }) =>
        this.authService.updateMe(data).pipe(
          map(({ user }) => actions.updateMeSuccess({ user: this.authService.createAuthenticatedUser(user) })),
          catchError((error: any) => of(actions.updateMeFailure({ error })))
        )
      )
    )
  );

  updateMeSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.updateMeSuccess),
        tap(({ user }) => {
          this.showToast('Profil bol úspešne aktualizovaný');
          this.router.navigate(['/tabs/profile']);
        })
      ),
    { dispatch: false }
  );

  logoutResult$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.logout),
      exhaustMap(() =>
        this.authService.logout().pipe(
          switchMap(() => from(this.tokenStorageService.removeToken())),
          tap(() => this.router.navigate(['/login'])),
          map(() => actions.logoutSuccess()),
          catchError(() => of(actions.logoutFailure))
        )
      )
    )
  );

  getMe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getMe),
      switchMap(() => from(this.tokenStorageService.getToken())),
      exhaustMap((token) =>
        token
          ? this.authService.me().pipe(
              map(({ user }) => actions.getMeSuccess({ user: this.authService.createAuthenticatedUser(user) })),
              catchError(() => of(actions.getMeFailure({ error: 'Relácia skončila. Prihláste sa znova prosím.' })))
            )
          : of(actions.getMeFailure({ error: null }))
      )
    )
  );

  getMeFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.getMeFailure),
        tap(({ error }) => {
          if (error) {
            this.showToast(error);
          }
        }),
        switchMap(() => from(this.tokenStorageService.removeToken()))
      ),
    { dispatch: false }
  );

  updateAbility$ = createEffect(
    () =>
      this.store.select(selectAuthenticatedUser).pipe(
        tap((user) => {
          this.abilityService.updateAbilitiesFor(user || null);
        })
      ),
    { dispatch: false }
  );

  private async showToast(message: string) {
    this.toastCtrl
      .create({
        message,
        duration: 4000,
      })
      .then((toast) => toast.present());
  }
}
