import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

export class StorageKey {
  private key: string;

  constructor(key: string) {
    this.key = `__pbgas_${key}`;
  }

  public toString() {
    return this.key;
  }
}

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private storage?: Storage;

  constructor(private storageCtrl: Storage) {}

  private async getStorage(): Promise<Storage> {
    if (!this.storage) {
      this.storage = await this.storageCtrl.create();
    }

    return this.storage;
  }

  public async get<T>(key: StorageKey): Promise<T | null> {
    const storage = await this.getStorage();
    const value = await storage.get(key.toString());

    if (value === null) {
      return null;
    }

    return JSON.parse(value);
  }

  public async set<T>(key: StorageKey, value: T): Promise<void> {
    const storage = await this.getStorage();
    const serializedValue = JSON.stringify(value);

    return storage.set(key.toString(), serializedValue);
  }

  public async remove(key: StorageKey): Promise<void> {
    const storage = await this.getStorage();

    return storage.remove(key.toString());
  }
}
