export const dataSet = <T = any, R = T>(obj: T, path: string, value: any) => {
  const keys = path.split('.');

  const result = { ...obj };

  let current: any = result;

  for (const key of keys) {
    if (current?.[key] === undefined) {
      current[key] = {};
    }

    if (key === keys[keys.length - 1]) {
      current[key] = value;
    }

    current = current[key];
  }

  return result as unknown as R;
};
