class ArrayBuilder<T> {
  private arr: T[] = [];

  add(value: T | T[]): ArrayBuilder<T> {
    if (Array.isArray(value)) {
      this.arr.push(...value);
    } else {
      this.arr.push(value);
    }

    return this;
  }

  when(condition: boolean, value: T | T[]): ArrayBuilder<T> {
    if (condition) {
      this.add(value);
    }

    return this;
  }

  build(): any[] {
    return this.arr;
  }
}

export function arrayBuilder<T>(value: T): ArrayBuilder<T>;
export function arrayBuilder<T>(arr: T[]): ArrayBuilder<T>;
export function arrayBuilder<T>(): ArrayBuilder<T>;
export function arrayBuilder<T>(arrOrValue?: T | T[]): ArrayBuilder<T> {
  const builder = new ArrayBuilder<T>();

  if (arrOrValue) {
    builder.add(arrOrValue);
  }

  return builder;
}
