import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { tap } from 'rxjs';
import { selectAuthenticatedUser } from 'src/app/core/auth/data/+state/auth.selectors';
import { ConfigService } from '../config.service';

@Injectable()
export class ConfigEffects {
  constructor(private actions$: Actions, private store: Store, private configService: ConfigService) {}

  setConfig$ = createEffect(
    () =>
      this.store.select(selectAuthenticatedUser).pipe(
        tap((user) => {
          this.configService.set(user?.config || null);
        })
      ),
    { dispatch: false }
  );
}
