<div *ngIf="hasChildren(item); else root">
  <ion-item [button]="true" [detail]="false" [routerLink]="[item.url]" routerDirection="root" lines="none" routerLinkActive="selected">
    <ion-icon slot="start" [ios]="item.icon + '-outline'" [md]="item.icon + '-sharp'"></ion-icon>
    <ion-label>{{ item.title }}</ion-label>
    <ion-icon [name]="itemDetailIcon(item)" slot="end" (click)="onClick($event, item)"></ion-icon>
  </ion-item>

  <div *ngIf="item.opened">
    <ng-container *ngFor="let child of item.children">
      <ion-item *ngIf="child" routerDirection="root" [detail]="false" [routerLink]="[child.url]" class="child">
        <!-- icon only for padding -->
        <ion-icon slot="start"></ion-icon>
        <ion-label>{{ child.title }}</ion-label>
      </ion-item>
    </ng-container>
  </div>
</div>

<ng-template #root>
  <ion-item routerDirection="root" [routerLink]="[item.url]" [detail]="false" lines="none" routerLinkActive="selected">
    <ion-icon slot="start" [ios]="item.icon + '-outline'" [md]="item.icon + '-sharp'"></ion-icon>
    <ion-label>{{ item.title }}</ion-label>
  </ion-item>
</ng-template>
