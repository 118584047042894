import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouteReuseStrategy, TitleStrategy } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { environment } from 'src/environments/environment';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { TitleStrategyService } from '../shared/ui/title-strategy.service';
import { ApiUrlInterceptor } from './api/api-url.interceptor';
import { IonicStorageModule } from '@ionic/storage-angular';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { ErrorInterceptor } from './error-handler/error.interceptor';
import { SharedModule } from '../shared/shared.module';
import { AuthEffects } from './auth/data/+state/auth.effects';
import { AuthModule } from './auth/auth.module';
import { TokenInterceptor } from './auth/data/services/token.interceptor';
import { ConfigEffects } from './config/data/+state/config.effects';
import { AUTH_FEATURE_KEY, authReducer } from './auth/data/+state/auth.reducer';
import { ROUTER_FEATURE_KEY, routerReducer } from './router/+state/router.reducer';
import { FORM_ERRORS_FEATURE_KEY, formErrorsReducer } from './error-handler/+state/form-errors.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    SharedModule,
    AuthModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot({
      name: '__pbgas_db',
    }),
    StoreModule.forRoot({
      [AUTH_FEATURE_KEY]: authReducer,
      [ROUTER_FEATURE_KEY]: routerReducer,
      [FORM_ERRORS_FEATURE_KEY]: formErrorsReducer,
    }),
    EffectsModule.forRoot([AuthEffects, ConfigEffects]),
    StoreRouterConnectingModule.forRoot(),
    environment.production
      ? []
      : StoreDevtoolsModule.instrument({
          name: 'PBGas app',
          maxAge: 50,
        }),
  ],
  exports: [],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    {
      provide: TitleStrategy,
      useClass: TitleStrategyService,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiUrlInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
  ],
})
export class CoreModule {}
